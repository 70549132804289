@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500&family=Roboto&display=swap');
@font-face {
  font-family: 'Sequel';
  src: url('assets/fonts/Sequel.ttf');
}
@font-face {
  font-family: 'Calibri';
  src: url('assets/fonts/calibri-regular.ttf');
}
@font-face {
  font-family: 'Calibri';
  src: url('assets/fonts/calibri-bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'Calibri';
  src: url('assets/fonts/calibri-italic.ttf');
  font-style: italic;
}
@font-face {
  font-family: 'Calibri';
  src: url('assets/fonts/calibri-bold-italic.ttf');
  font-weight: bold;
  font-style: italic;
}

:root {
  --primary-color: #328cb4;
  --dark-color: #132b50;
  --light-color: #ffffff;
  --grey-color: #cccccc;
  --orange-color: #ffa740;
  --blue-color: #0d4491;
  --green-color: #137c55;
  --red-color: #ff4d68;
  --danger-color: #ff4d68;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Calibri', sans-serif !important;
  font-size: 14px !important;
  color: var(--dark-color) !important;
  font-style: normal;
  overflow: hidden;
}
.container-fluid {
  background-color: var(--light-color);
}
.app-content {
  height: calc(100vh - 50px);
  overflow: hidden;
}
.subTitle {
  padding-bottom: 10px !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #282561;
}
a:hover {
  text-decoration: none;
}

/* Forms */
.form-control {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.4rem 1rem !important;
  height: calc(1em + 1.3rem) !important;
}
.form-control::placeholder,
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: rgba(113, 121, 134, 0.75) !important;
  opacity: 1;
}
.form-group {
  margin-bottom: 11px !important;
}
.form-group .label {
  color: var(--dark-color);
  font-size: 12px;
  margin-bottom: 0.1rem !important;
}
.form-group .required-star {
  color: var(--danger-color);
}
.date-range {
  display: flex;
  padding: 5px 10px;
  border: 1px solid var(--grey-color);
  border-radius: 3px;
  background: var(--light-color);
  align-items: center;
  cursor: pointer;
}
.date-range > span {
  padding-left: 5px !important;
  padding-right: 5px;
  font-size: 0.8rem !important;
  color: #9c9c9c;
  font-weight: 500;
  height: 0.85rem !important;
}
.date-container {
  top: 40px;
  left: auto;
  right: 0px;
  display: block;
  z-index: 9999 !important;

  position: absolute;
  border-radius: 4px;
  border: 1px solid var(--grey-color);
  padding: 0;
  margin: 0px;
  margin-top: 7px;
  background-color: var(--light-color);
}
.date-container:before {
  left: 360px;

  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;

  position: absolute;
  display: inline-block;
  content: '';
}
.rdrDateRangePickerWrapper {
  width: 100%;
}
.rdrCalendarWrapper {
  width: 100%;
}
.rdrMonthAndYearWrapper {
  padding-top: 0px !important;
  height: auto !important;
}
.rdrMonthName {
  padding: 0.2rem 0.85rem !important;
}
.rdrWeekDay {
  line-height: auto !important;
}
.rdrDay {
  height: 2.5rem !important;
}

.search-control {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--dark-color);
  border-radius: 0.25rem;
  padding: 0.2rem 1rem !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
.search-control::placeholder,
.search-control:-ms-input-placeholder,
.search-control::-ms-input-placeholder {
  color: rgba(113, 121, 134, 0.75) !important;
  opacity: 1;
}
.number {
  width: 100px;
}
.notes {
  height: 100px !important;
}
.label-total {
  padding-top: 3px;
  font-weight: bold;
}
.react-datepicker:has(.react-datepicker__time-container) {
  width: 298px;
}

/* Button */
.btn {
  text-align: center;
  display: inline-block;
  border-radius: 0.25rem;
  padding: 0.4rem 1.5rem;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  font-size: 12px !important;
  line-height: 16px;
}
.btn-block {
  width: 100% !important;
}
.btn-primary {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}
.btn-delete {
  border: 1px solid var(--danger-color) !important;
  background-color: var(--danger-color) !important;
  color: var(--light-color);
}
.btn-primary:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}
.btn-primary-outline {
  border: 1px solid var(--primary-color) !important;
  background-color: transparent !important;
  color: var(--primary-color);
}
.btn-primary-outline:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}
.btn-primary.dropdown-toggle::after {
  color: var(--light-color) !important;
}
.btn-signup {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px !important;
  line-height: 18px;
}
.btn-module {
  padding: 0.2rem 1.5rem;
}
.btn-module span {
  margin-top: 0.25rem;
}
.btn-export {
  padding: 0.4rem 0.6rem;
}
.dropdown-toggle::after {
  margin-left: 0.555em;
  vertical-align: 0.155em;
}
.limit-menu {
  transform: translate3d(0px, 26px, 0px) !important;
  min-width: 75px !important;
}
.btn-prevnex,
.btn-apply {
  border: 1px solid var(--grey-color) !important;
  padding: 0.2rem 1.5rem;
  background-color: var(--light-color) !important;
}
.btn-prevnex:hover {
  border: 1px solid var(--light-color) !important;
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
  background-color: rgb(34, 155, 204) !important;
}
.btn-apply {
  padding: 0.2rem 0.5rem;
}
.btn-save,
.btn-back {
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0.2rem 1rem;
}
.btn-save:hover,
.btn-back:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}
.btn-save {
  border: 1px solid var(--light-color) !important;
  background-color: var(--light-color) !important;
  color: var(--primary-color);
}
.btn-save:hover {
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}
.btn-back {
  border: 1px solid var(--light-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}
.btn-back:hover {
  background-color: var(--light-color) !important;
  color: var(--primary-color);
}

/* Error */
.notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  line-height: 1.4;
  padding-left: 20px;
  padding-right: 20px;
}
.notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}
.notfound .notfound-404 h1 {
  color: var(--light-color);
  font-weight: 900;
  font-size: 276px;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound h2 {
  font-size: 46px;
  color: var(--dark-color);
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
}
.notfound p {
  font-size: 16px;
  color: var(--dark-color);
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}
.notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: var(--primary-color);
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: var(--light-color);
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

/* Navbar */
.navbar {
  background-color: var(--light-color);
  box-shadow: 0 1px 1px 1px rgba(18, 106, 211, 0.08);
}
.sidemenu-opener {
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 5px;
  border-radius: 5px;
  padding-top: 8px;
  background-color: rgb(225, 243, 251);
  box-shadow: 0 1px 1px 1px rgba(18, 106, 211, 0.18);
  cursor: pointer;
}
.sidemenu-opener.hidden {
  background-color: rgb(225, 243, 251) !important;
}
.navbar-brand {
  padding: 0px;
}
.navbar-brand img {
  height: 30px !important;
  margin-left: 5px;
}
.notification {
  color: var(--dark-color);
  padding: 8px 0px;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgb(177 177 189 / 52%) !important;
}

/* Navbar Profile */
.user-profile {
  padding: 0px;
}
.user-name {
  font-weight: 500 !important;
  color: var(--dark-color);
}
.user-status {
  font-weight: 400 !important;
  font-size: 12px;
  color: rgba(91, 91, 91, 0.7);
}
.avatar {
  white-space: nowrap;
  background-color: #c3c3c3;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgb(177 177 189 / 52%) !important;
  position: relative;
  display: inline-flex;
}
.avatar img {
  border-radius: 50%;
  height: 35px;
  width: 35px;
}
.dropdown-toggle::after {
  color: var(--dark-color);
}
.dropdown-menu {
  border: 0;
  right: 10px;
  left: auto;
  top: 100%;
  padding: 0;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}
.dropdown-item {
  font-weight: 500;
  color: rgba(113, 121, 134);
  padding: 7px 10px;
}
.dropdown-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  color: var(--dark-color);
}
.dropdown-text {
  font-weight: 400 !important;
  font-size: 12px;
}
.logout {
  color: rgba(255, 40, 92, 1) !important;
  font-weight: 500 !important;
}

/* Sidebar */
.sidebar {
  padding-top: 10px !important;
  height: 100%;
  display: none;
  overflow: auto;
  padding: 0px 0px 15px 0px;
  background-color: var(--light-color) !important;
  box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.1);
}
.sidebar.active {
  display: block;
}
.sidebar::-webkit-scrollbar {
  width: 5px;
}
.sidebar::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.sidebar-sticky .nav {
  min-height: calc(100vh - 115px);
}
.nav-arrow {
  width: 8px;
  height: 8px;
}
.nav-arrow.down {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.nav-link {
  font-weight: 500;
  color: rgba(113, 121, 134);
  padding: 3px 10px;
  cursor: pointer;
}
.nav-link.active {
  background-color: rgba(52, 174, 226, 0.15);
}
.nav-link.active span,
.nav-link.active svg {
  color: var(--primary-color);
}
.sidebar-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.sidebar-text {
  font-weight: 400 !important;
  font-size: 12px;
}
.nav-submenu .nav-item .nav-link {
  padding-left: 30px;
}

/* Login */
.login-card {
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  width: 350px;
}
.login-header {
  margin-bottom: 5vh;
  text-align: center;
}
.login-header img {
  width: 200px;
  padding-top: 10px;
}
.login-title {
  font-weight: 600;
  font-size: 24px;
}
.login-label {
  color: var(--dark-color);
  font-size: 12px;
}
.remember-me {
  line-height: 22px;
  font-size: 12px;
}
.forget-password {
  color: var(--primary-color);
  line-height: 22px;
  font-size: 12px;
}

/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin-left: 5px;
  margin-right: 10px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: var(--primary-color);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}
input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

/* Bottom Nav */
.bottom-nav {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: var(--light-color);
  border-top: 1px solid var(--grey-color) !important;
  justify-content: space-evenly;
  direction: ltr;
  z-index: 999;
  opacity: 2;
}
.nav-component.show {
  color: #2cacdb;
  background-color: white;
}
.nav-dashboard-component:hover {
  color: #2cacdb;
  background-color: white;
}
.bottom-nav-link:active {
  background-color: white;
  color: #2cacdb;
}
.wrap {
  padding-top: 8px;
  text-align: center;
  height: 24px;
  color: var(--grey-color);
}
.wrap.active {
  color: var(--primary-color);
}
.bottom-nav-icon {
  height: 24px;
}
.bottom-nav-icon svg {
  width: 24px;
  height: 24px;
}

/* Bottom Nav*/
.bottom-nav {
  display: none;
}

/* Module */
.module {
  /* background-color: var(--primary-color); */
  background: linear-gradient(to right, #299af3, #b5dcfb);
  color: var(--light-color);
  padding: 0.25rem 0.75rem;
}
.module-img {
  height: 1.5rem;
  margin-right: 10px;
}
.module-title {
  font-weight: 600;
  font-size: 20px;
  margin-top: 2px;
}
.content {
  padding: 20px;
}
.list-card-header {
  padding: 5px 22px !important;
  flex: 0 0 55px !important;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
}
.list-card-body {
  padding: 10px;
  background: #fff;
  border-radius: 0 0 5px 5px;
}
.main {
  height: 100%;
  padding: 0px;
  min-height: calc(100vh - 130px);
  overflow: scroll;
}
.detail {
  border: 1px solid #eaeaea;
  margin: 0 0px;
  padding: 20px 20px;
  height: 100%;
  box-shadow: 0px 1px 5px 1px rgba(69, 65, 78, 0.08);
}

/* Table */
.table-list td,
.table-list th {
  padding: 0.35rem;
  font-size: 12px;
  font-weight: 300;
  vertical-align: middle;
  color: var(--dark-color);
}

.table-list thead td,
.table-list thead th,
.table-list tfoot td,
.table-list tfoot th {
  background-color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.table-list .header {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}
.table-list .text-right {
  padding-right: 20px;
}
.table-list .sorting {
  background-image: url('assets/images/sort_both.png');
}
.table-list .sorting_asc {
  background-image: url('assets/images/sort_asc.png');
}
.table-list .sorting_desc {
  background-image: url('assets/images/sort_desc.png');
}

.table-list .data-item {
  cursor: pointer;
}

.download-link {
  color: var(--dark-color);
  cursor: pointer;
}

.CANCELED {
  text-decoration: line-through;
}

/* Mobile */
.narrow-screen {
  display: none !important;
}
.module-card {
  box-shadow:
    rgba(16, 24, 40, 0.1) 0px 4px 6px -1px,
    rgba(16, 24, 40, 0.1) 0px 2px 4px -2px;
  margin-bottom: 16px;
  border-radius: 20px;
}
.module-card-header {
  min-height: 80px;
  background-color: var(--light-color);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease 0s;
  border-left: 15px solid var(--primary-color);
}
.module-card-title {
  color: black;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}
.module-card-subtitle {
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: -25px;
}
.module-card-pointer {
  cursor: pointer;
}
.module-card-pointer svg {
  color: var(--light-color);
  min-height: 18px;
  min-width: 18px;
  background-color: var(--primary-color);
  border-radius: 3.5px;
}
/* .module-card-body {
  background-color: rgb(255, 255, 255);
  border-radius: 0px 0px 5px 5px;
  transition: all 0.5s ease 0s;
  padding: 16px;
  display: none;
}
.module-card-body.active {
  display: block;
} */
.module-card-body {
  background-color: rgb(255, 255, 255);
  border-radius: 5px 5px 5px 5px;
  transition: all 0.5s ease 0s;
  padding: 16px;
  max-width: 500px;
  width: 350px;
  max-height: 90vh;
  position: fixed;
  top: 55%; 
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  display: none;
}

.module-card-body.active {
  display: block; 
}

.module-card-overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none; 
}
.module-card-overlay.active {
  display: block;
}
.table-card {
  width: 100%;
}
.table-card td {
  vertical-align: top;
  padding-bottom: 5px;
}

.mobile-card-container {
  width: 100% !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  padding-top: 10px !important;
  overflow: hidden;
}
.mobile-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.mobile-card-single {
  width: 25%;
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  text-decoration: none;
  color: var(--primary-color);
  align-items: center;
}
.mobile-card-scaler {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.mobile-card-single:hover .mobile-card-scaler {
  background-color: #2cacdb;
  color: white;
  border: 1px solid var(--light-color);
  border-radius: 20px;
}
.mobile-card-single:hover .mobile-card-text {
  color: #2cacdb;
  font-weight: 700;
}

.mobile-card-image {
  display: flex;
  justify-content: center;
  align-items: center !important;
  border-radius: 20px;
  height: 60px;
  width: 60px;
  border: 1px solid var(--primary-color);
}
.mobile-card-image svg {
  width: 50%;
  height: 50%;
}
.mobile-card-text {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}

.map {
  height: calc(100% - 35px);
  min-height: 500px;
  border: 1px solid var(--grey-color);
}

.order-status {
  padding: 20px;
}
.order-status-item {
  margin-right: 35px;
  padding-right: 15px;
  border-top: 1px solid var(--grey-color);
  border-right: 1px solid var(--grey-color);
  border-bottom: 1px solid var(--grey-color);
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.order-status-mark {
  margin-left: -1.5rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 1px solid var(--grey-color);
  padding: 3px;
}
.order-status-icon {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.order-status-icon svg {
  color: var(--dark-color);
}
.order-status-icon.active {
  background-color: var(--primary-color);
}
.order-status-icon.active svg {
  color: var(--light-color);
}
.order-status-text {
  padding-right: 8px !important;
  padding-left: 8px !important;
  font-weight: 600;
}
.item-table .table-responsive {
  padding-bottom: 75px;
}

/* Dashboard */
.dashboard {
  color: var(--primary-color);
  padding: 0.75rem 1rem;
  font-size: 1.2rem;
}
.dashboard-title {
  font-weight: 600;
  font-size: 1.5rem;
}
.btn-sync {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--light-color) !important;
  color: var(--primary-color);
  padding: 0.375rem 0.75rem;
  line-height: 12px;
}
.btn-sync:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}

/* Achivement */
.achievement-wrapper {
  background: linear-gradient(43deg, #020024 0%, #090979 35%, #00d4ff 100%);
  margin-bottom: 1.2rem;
  padding: 15px !important;
  color: var(--light-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 5px;
}
.achievement-head {
  position: relative;
  margin-bottom: 60px;
}
.achievement-head:before {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  content: '';
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
}
.achievement-head:after {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 1;
}
.achievement-head-title {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  float: left;
}
.achievement-head-title small {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: var(--grey-color);
}
.achievement-head-option {
  font-size: 16px;
  font-weight: 400;
  float: right;
}
.achievement-content-left {
  float: left;
  padding: 10px 30px;
  color: #333;
  background-color: #e7f2ee;
  border-radius: 5px;
}
.achievement-content-left .mc-fa {
  color: #439678;
  border: 2px solid #439678;
  border-radius: 100%;
  padding: 5px;
  font-size: 22px;
  height: 38px;
  width: 38px;
  vertical-align: middle;
}
.achievement-content-left div {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
}
.achievement-content-right {
  float: right;
  margin-top: 10px;
  position: relative;
}
.achievement-content-right div {
  float: left;
  text-align: center;
  margin-right: 60px;
  position: relative;
}
.achievement-content-right .mc-fa {
  position: absolute;
  top: 0;
  right: -30px;
  font-size: 18px;
}
.achievement-content-left div small,
.achievement-content-right div small {
  display: block;
  font-size: 1rem;
  font-weight: 500;
}
.achievement-content-left strong,
.achievement-content-right strong {
  display: block;
  font-size: 28px;
  line-height: normal;
}
.achievement-content-right:before {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  content: '';
  position: absolute;
  bottom: 0px;
  right: 10px;
  z-index: 1;
}
.achievement-content-right:after {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  content: '';
  position: absolute;
  bottom: -20px;
  right: -10px;
  z-index: 1;
}

/* Fleet Order */
.fleet-order-wrapper {
  padding: 15px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 1.2rem;
}
.fleet-order-head {
  position: relative;
  margin-bottom: 30px;
}
.fleet-order-head-title {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  float: left;
}
.fleet-order-head-title small {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: var(--grey-color);
}
.fleet-order-head-option {
  font-size: 16px;
  font-weight: 400;
  float: right;
}
.fleet-order-head-option-item label {
  margin-bottom: 0;
  color: var(--primary-color);
}
.fleet-order-content-item div {
  position: relative;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}
.fleet-order-content div small {
  display: block;
  font-size: 1rem;
  font-weight: 500;
}
.fleet-order-content div strong {
  display: block;
  font-size: 28px;
  line-height: normal;
}
.fleet-order-content-item div .mc-fa {
  position: absolute;
  bottom: 10px;
  right: 1px;
  padding: 10px;
  height: 42px;
  width: 42px;
  border-radius: 100%;
  text-align: center;
}
.fleet-order-content-item .mc-orange {
  color: #ffa740;
  background-color: #fff3e6;
}
.fleet-order-content-item .mc-purple {
  color: #282561;
  background-color: #ebecef;
}
.fleet-order-content-item .mc-green {
  color: #429677;
  background-color: #e7f2ee;
}

.chart {
  height: 195px;
}
.chart canvas {
  width: 100% !important;
  height: 100% !important;
}

.table-reminder th,
.table-reminder td {
  font-size: 12px;
  padding: 5px 10px;
}
.table-reminder .title {
  font-weight: bold;
}
.table-reminder .badge {
  padding: 5px 10px;
}

/* Order */
.order-wrapper {
  padding: 15px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 1.2rem;
}
.order-head {
  position: relative;
  margin-bottom: 30px;
}
.order-head-title {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  float: left;
}
.order-head-title small {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: var(--grey-color);
}
.order-head-option {
  font-size: 16px;
  font-weight: 400;
  float: right;
}
.order-head-option-item label {
  margin-bottom: 0;
  color: var(--primary-color);
}
.order-content-item div {
  position: relative;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  color: var(--dark-color);
}
.order-content div small {
  display: block;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  padding-left: 15px;
}
.order-content-item small:before {
  background-color: #67718b;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 100%;
  top: 6px;
  position: absolute;
  left: 0;
}
.order-content-item small.mc-orange {
  color: var(--orange-color);
}
.order-content-item small.mc-orange:before {
  background-color: var(--orange-color);
}
.order-content-item small.mc-blue {
  color: var(--blue-color);
}
.order-content-item small.mc-blue:before {
  background-color: var(--blue-color);
}
.order-content-item small.mc-green {
  color: var(--green-color);
}
.order-content-item small.mc-green:before {
  background-color: var(--green-color);
}
.order-content-item small.mc-red {
  color: var(--red-color);
}
.order-content-item small.mc-red:before {
  background-color: var(--red-color);
}
.order-content div strong {
  display: block;
  font-size: 20px;
  line-height: normal;
  text-align: right;
}
.order-content-total div {
  position: relative;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  color: var(--dark-color);
  background-color: #4a90e1;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  margin-top: -10px;
}
.order-content-total div small {
  padding-left: 0px;
}
.order-content-total div strong {
  display: block;
  font-size: 28px;
  text-align: center;
}
.equal {
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}

/* Style Modal */

.modal-header {
  background-color: #f8f8f8;
  padding: 0.8rem 1.4rem !important;
}

.modal-dialog {
  max-width: 800px !important;
}

@media (max-width: 600px) {
  *::-webkit-scrollbar {
    width: 0.4em;
    border-radius: 2em;
  }
   
  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 2em;
  }
   
  *::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 2em;
  }
  .custom-sticky {
    width: 100%;
    height: 60px;
    background-color: var(--light-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .FooterModule {
    background-color: var(--light-color);
    display: block;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    text-align: center;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    box-shadow: 0px 1px 5px 1px rgba(69, 65, 78, 0.08);
    max-height: 0px !important;
    overflow: hidden;
    transform: translateY(100%) !important;
    transition: all 0.5s ease-in-out;
    opacity: 0;
  }

  .FooterModule.show {
    padding-top: 0 !important;
    max-height: 300px !important;
    padding: 15px !important;
    transform: translateY(0) !important;
    overflow: auto !important;
    z-index: 998 !important;
    transition: all 0.5s ease-in-out;
    opacity: 1;
  }

  .FooterModule {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    transform: translateY(100%);
  }

  .FooterModule.show {
    padding-top: 0 !important;
    max-height: 300px; /* adjust as needed */
    opacity: 1;
    transition: all 0.5s ease-in-out;
    transform: translateY(0);
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    background: rgba(0, 0, 0, 0.2); /* Adjust the opacity as needed */
    z-index: 997; /* Make sure it's behind the menu */
  }

  .hiasanFooter {
    margin: 0 auto;
    width: 80px;
    background-color: var(--grey-color);
    height: 8px;
    border-radius: 10px;
    z-index: 1000; /* increase z-index to ensure it's on top */
  }
}

@media (min-width: 600px) {
  .modal-dialog {
    max-width: 800px;
    max-height: 700px;
    margin: 1.75rem auto;
  }

  .periodinfo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

.subTitle {
  padding-bottom: 10px !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #282561;
  cursor: pointer;
  position: relative;
}
.subTitle:hover {
  text-decoration: none;
}
.subTitle.active::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #59afec;
}
.subTitle-license {
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: black;
  cursor: pointer;
  position: relative;
  background-color: #ededef;
  border-radius: 5px;
  width: 20%;
  height: 90px;
  border-left: 8px solid #dc8612;
}
.subTitle-license p {
  padding-top: 15px;
  margin-left: 10px;
}
.subTitle-license:hover {
  text-decoration: none;
}
.subTitle-license.active::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #59afec;
}

.subTitle-type {
  padding-bottom: 10px !important;
  font-weight: 600 !important;
  font-size: 1.1rem !important;
  color: #282561;
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.subTitle-type.active {
  background-color: #3498db;
  color: white;
}
.subTitle-type:hover:not(.active) {
  background-color: #70c1ff;
  color: white;
}

.container-detail {
  background-color: #e1f4fb;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.container-type-license {
  display: flex;
  align-items: center;
}

.type-license {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 120px;
}

.save-button-license {
  background-color: #2b9af3;
  color: white;
  margin-left: 10px;
  border: 1px solid #2b9af3;
  width: 130px;
  height: 30px;
  border-radius: 10px;
}

.save-button-license:hover {
  background-color: white;
  color: #2cacdb;
  border: 1px solid #59afec;
}

.detail-txt {
  font-size: 32px;
  font-weight: 700;
  font-family: 'Roboto';
  color: #2b83b5;
}

.container-license {
  display: flex;
  gap: 80px;
}
.container-license p,
.total-license {
  margin: 0;
}
.container-license > div {
  box-sizing: border-box;
}
.container-license p span {
  color: black;
}
.add-user {
  display: block;
  color: black;
  display: inline-block;
  border: 0px solid #d9eff7;
  background-color: #d9eff7;
  color: black;
  padding: 5px 10px;
  border-radius: 0 0 0 0;
}
.container-license button {
  border: 1px solid #d9eff7;
  background-color: #d9eff7;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  width: 30px;
}
.container-license button:hover {
  background-color: #bcdde7;
}
.container-license button:focus,
.container-license button:active {
  outline: none;
}
.monthly-payment h2 {
  font-size: 26px;
  font-weight: 300px;
  font-family: 'Roboto';
  color: black;
}
.total-amount {
  font-weight: bold;
  font-size: 40px;
  color: #dc8612;
  display: flex;
  align-items: baseline;
}
.total-amount p {
  font-size: 16px;
  color: #31363f;
  margin-left: 10px;
}
.incvoice-container-filter {
  display: flex;
  align-items: center;
  gap: 180px;
  justify-content: center;
}
.calendar-invoice img {
  width: 55px;
  height: 55px;
  cursor: pointer;
}
.txt-filter-invoice {
  margin-left: 10px;
}
.txt-filter-invoice span {
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
}
.tgl-filter-invoice {
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 600;
  margin-top: 3px;
}
.line-filter-invoice {
  border-right: 2px solid #a9a9a9;
  height: 80px;
  margin: 0 5px;
  margin-top: 15px;
}

.modal-container-filter {
  display: flex;
  align-items: center;
  gap: 45px;
  justify-content: center;
}
.contain-filter {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.txt-filter {
  margin-left: 10px;
}
.txt-filter span {
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 500;
}
.tgl-filter {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 600;
  margin-top: 3px;
}
.line-filter {
  border-right: 1px solid #a9a9a9;
  height: 40px;
  margin: 0 5px;
  margin-top: 15px;
}
.modal-btn {
  border-radius: 13px;
  height: 35px;
  align-self: end;
  justify-content: center;
  padding: 3px 20px;
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
  font-size: 12px;
  font-weight: 400;
  font-family: 'Roboto';
}
.circle-icon {
  width: 40px;
  height: 50px;
  border-radius: 10%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-cash {
  display: flex;
  align-items: center;
}
.txt-cash-span {
  margin-left: 10px;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 700;
  color: #000;
  position: relative;
  align-items: center;
}
.txt-cash-span p {
  margin-top: 5px;
  font-size: 10px;
  align-items: center;
  font-weight: 400;
}
.txt-cash-span2 {
  margin-left: 10px;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 700;
  color: #000;
  position: relative;
  margin-right: 10px;
}
.txt-cash-span2 p {
  margin-top: 5px;
  font-size: 10px;
  align-items: center;
  font-weight: 400;
}
.txt-minus {
  margin-left: 10px;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  color: #ff0000;
  margin-bottom: 40px;
  position: relative;
  top: -8px;
}
.container-invoice {
  width: 100%;
  height: 100px;
  border: 1px solid #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.icon-invoice {
  margin-left: 20px;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 700;
  color: #000;
  position: relative;
  margin-right: 10px;
}
.icon-invoice div {
  margin-top: 5px;
  font-size: 16px;
  align-items: center;
  font-weight: 400;
}
.circle-invoice {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-box div {
  width: 100px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-family: 'Roboto';
  margin-bottom: 10px;
}
.status-box {
  flex-direction: column;
  margin-top: 6px;
}
.status-box .pending {
  background-color: #dc8612;
}

.status-box .paid-off {
  background-color: #41b06e;
}

.status-box span {
  font-size: 18px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  color: black;
  margin-right: 20px;
}
.icon-bookmark {
  width: 40px;
  height: 40px;
}
.icon-paid {
  width: 150px;
  height: 150px;
}

.invoice-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}
.invoice-date {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  font-size: 16px;
}
.total-invoice {
  text-align: center;
  margin-top: 30px;
}
.total-invoice p {
  font-size: 16px;
  font-weight: 600;
}
.total-invoice h2 {
  font-weight: 700;
  color: black;
}
.invoice-detail p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.invoice-detail h2 {
  text-align: center;
  color: black;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
}
.long-input {
  width: 100%;
}
.mobile-action-card {
  padding: 0;
  background-color: var(--primary-color);
  position: fixed;
  bottom: 155px;
  right: 15px;
  width: 168px;
  height: 62px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
.mobile-action-card span {
  color: var(--light-color);
  font-size: 16px;
  text-align: right;
  margin: auto 0;
  margin-left: 10px;
}
.mobile-action-card-link {
  display: flex;
  gap: 18px;
}
.mobile-action-icon {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background-color: var(--light-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-action-icon svg {
  height: 20px;
  width: 20px;
  color: var(--primary-color);
}
.Add-Circle {
  position: fixed;
  right: 15px;
  bottom: 80px;
  height: 62px;
  width: 62px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.5s;
}
.AddCircle1 {
  height: 30px;
  width: 3px;
  background-color: var(--light-color);
  position: relative;
  right: -15.5px;
  transition: all 0.5s;
}
.AddCircle2 {
  height: 3px;
  width: 30px;
  background-color: var(--light-color);
  transition: all 0.5s;
}
.Add-Circle.show {
  background-color: var(--danger-color);

  .AddCircle1 {
    rotate: 45deg;
  }
  .AddCircle2 {
    rotate: 45deg;
  }
}

.groupMenu.active:before {
  content: '';
  position: absolute;
  top: -25%;
  left: 25%;
  width: 0;
  height: 0;
  border: 1rem solid transparent;
  border-bottom-color: var(--primary-color);
  border-top: 0;
  z-index: 999;
}

.mobileGroupMenu {
  margin-bottom: 15% !important;
}

.mobileGroupMenu .modal-body{
    overflow: scroll;
  max-height: 30vh;
}

.mobileGroupMenu,
.mobileGroupMenu .modal-title .custom-sticky {
  border-top-left-radius: 4rem;
  border-top-right-radius: 4rem;
}

.modal-header .btn-close {
  border-radius: 100%;
  border: solid 0.01rem black;
  padding: 0.02rem;
}

.modal-header .btn-close svg {
  rotate: 45deg;
  vertical-align: middle;
}

.periodinfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.btn-search {
  border: 1px solid #28c76f !important;
  background-color: #28c76f !important;
  color: var(--light-color);
}
.filter {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.status-box span {
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  color: black;
  text-align: center;
}
.status-box-list div {
  width: 100px;
  height: 34px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-family: "Roboto";
  margin-bottom: 10px;
}
.status-box-list {
  flex-direction: column;
  margin-top: 6px;
}
.status-box-list .pending {
  background-color: #FF0000;
}

.status-box-list .paid-off {
  background-color: #1BBF00;
}

.status-box-list span {
  font-size: 18px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  color: black;
  text-align: center;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.status-box2, .status-box {
  display: flex;
  align-items: center;
}

.status-box2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-top: 30px;
}

.status-box2 button {
  border: none;
  color: white;
  width: 100px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.status-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.pending, .paid-off {
  background-color: white;
  color: #12A3DC;
  padding: 5px 10px;
  border-radius: 5px;
}
.subTitle-container {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
}
.subTitle {
  padding-bottom: 10px !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #282561;
  cursor: pointer;
  position: relative;
  flex: 0 0 auto;
  white-space: nowrap;
  cursor: pointer;
}
.date-range-arrow {
  position: absolute;
  right: 25px;
}.btn-small {
  padding: 4px 8px;
  font-size: 10px;
  line-height: 1.2;
  height: auto; 
}
/* .pagination {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  background-color: #12A3DC;
}.pagination button {
  position: relative;
  background-color: white;
  border: none;
  color: #12A3DC;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}.pagination button:disabled {
  background-color: #A9A9A9;
  cursor: not-allowed;
}*/
.pagination .prev-button::before,
.pagination .next-button::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
}.img-invoice {
  text-align: center;
  margin-top: 30px;
}

